import {
  SettingOutlined,
  PlusSquareOutlined,
  ApartmentOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH , Demo } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'product-list',
    path: `${APP_PREFIX_PATH}/products-list`,
    title: 'Product List',
    icon: PlusSquareOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'addproduct',
    path: `${APP_PREFIX_PATH}/add-product`,
    title: 'AddProduct',
    icon: PlusSquareOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'setting',
    path: `${APP_PREFIX_PATH}/setting/general`,
    title: 'Setting',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'orders',
    path: `${APP_PREFIX_PATH}/orders`,
    title: 'Orders',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'sellerAcadmy',
    path: `${APP_PREFIX_PATH}/seller-acadmy`,
    title: 'Seller Acadmy',
    icon: ApartmentOutlined,
    breadcrumb: false,
    submenu: []
  }


]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
