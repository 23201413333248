import React, { useEffect } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {
  signOutSuccess
} from 'redux/actions/Auth';
import { useHistory } from "react-router-dom";
import { BASE_URL,API_BASE_URL } from "configs/AppConfig";
const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/app/setting/general"
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/app/setting/company-details"
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/"
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  }
]

export const NavProfile = (props) => {
  const { signOutSuccess, token, name, profile_image } = props;
  let signOut = () => {
    signOutSuccess();
  }
  let history = useHistory();
  useEffect(() => {
    if (token == null) {
      history.push('/auth/login');
    }
    // console.log('props ----->', props);
    // console.log('name ----->', name);
    // console.log('profile_image ----->', profile_image);

  });



  let profileImg = `${BASE_URL}/img/avatars/thumb-1.jpg`;
  if (profile_image) {
    profileImg = `${API_BASE_URL}/uploads/profile/${profile_image}`;
  }
  // console.log('profile_image',profileImg);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted"></span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({ auth }) => {

  const { signOutSuccess, redirect, token, name, profile_image } = auth;
  return { signOutSuccess, redirect, token, name, profile_image }
}

const mapDispatchToProps = {
  signOutSuccess
}
export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
