import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from '../constants/ThemeConstant';
// import { env } from './EnvironmentConfig'

export const APP_NAME = 'Emilus';


export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const Demo = 'https://mewline.document360.io/'

// for staging
// export const BASE_URL = 'https://stagingseller.mewline.com'
// export const API_BASE_URL = 'https://stagingapi.mewline.com/api/v1'
// export const CUSTOMER_ENDPOINT_URL = 'https://staging.mewline.com'
// export const SUBFOLDER = '/'

// for live 
export const BASE_URL = 'https://seller.mewline.com'
export const API_BASE_URL = 'https://api.mewline.com/api/v1'
export const API_DOMAIN_URL = 'https://api.mewline.com'
export const CUSTOMER_ENDPOINT_URL = 'https://mewline.com'
export const SUBFOLDER = '/'

// for local
// export const BASE_URL = 'http://localhost:3007'
// export const API_BASE_URL = 'http://localhost:5002/api/v1'
// export const API_DOMAIN_URL = 'http://localhost:3002'
// export const CUSTOMER_ENDPOINT_URL = 'http://localhost:3000'
// export const SUBFOLDER = '/'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
     