import {
	AUTH_TOKEN, AUTH_NAME, AUTH_PROFILE_IMAGE, AUTH_ID,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	name: localStorage.getItem(AUTH_NAME),
	profile_image: localStorage.getItem(AUTH_PROFILE_IMAGE),
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			localStorage.setItem(AUTH_TOKEN, action.token);
			localStorage.setItem(AUTH_ID, action.user_id);
			localStorage.setItem(AUTH_NAME, action.name);
			localStorage.setItem(AUTH_PROFILE_IMAGE, action.profile_image);
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				name: action.name,
				profile_image: action.profile_image
			}
		case SHOW_AUTH_MESSAGE:

			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {

			localStorage.removeItem(AUTH_TOKEN);
			localStorage.removeItem("user_id");
			localStorage.removeItem("name");
			localStorage.removeItem("profile_image");
			return {
				...state,
				token: null,
				redirect: '/auth/login',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth