import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, SUBFOLDER } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch basename={SUBFOLDER}>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/setting`} component={lazy(() => import(`./setting`))} />
        <Route path={`${APP_PREFIX_PATH}/add-product`} component={lazy(() => import('./e-commerce/add-product/add-product'))}/>
        <Route path={`${APP_PREFIX_PATH}/products-list`} component={lazy(() => import(`./e-commerce/product-list`))}/>
        <Route path={`${APP_PREFIX_PATH}/edit-product/:id`} component={lazy(() => import(`./e-commerce/edit-product/edit-product`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import('./e-commerce/orders'))}/>
        <Route path={`${APP_PREFIX_PATH}/order/:id`} component={lazy(() => import('./e-commerce/orders/details'))}/>
        <Route path={`${APP_PREFIX_PATH}/seller-acadmy`} component={lazy(() => import('./e-commerce/seller-acadmy/index'))} />
        

        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);